.projects_outmain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Sets height equal to the viewport height to center `project_main` vertically */
  text-align: center;
  cursor: pointer;
}

.project_main {
  /* border: 1px solid black; */ /* This line is commented out */
  width: 620px;
  height: 620px;
  display: flex;
  flex-wrap: wrap; /* Allows sub-elements to wrap onto the next line when space runs out */
  align-content: flex-start; /* Aligns the `.projects_submain` elements at the start */
}

.project_main_top_bottom {
  width: 610px;
  /* border: 1px solid black; */ /* Uncomment this line if you need a border */
  height: 100px;
  display: flex;
  flex-wrap: wrap; /* Allows sub-elements to wrap onto the next line when space runs out */
  align-content: flex-start; /* Aligns the `.projects_submain` elements at the start */
}

.project_main_mid_side {
  width: 100px;
  /* border: 1px solid black; */ /* Uncomment this line if you need a border */
  height: 400px;
  display: flex;
  flex-wrap: wrap; /* Allows sub-elements to wrap onto the next line when space runs out */
  align-content: flex-start; /* Aligns the `.projects_submain` elements at the start */
}

.project_main_mid_center {
  width: 400px;
  height: 400px;
}

.projects_submain {
  width: 100px;
  height: 100px;
  border: 1px solid black;
  border-radius: 5px;
  box-sizing: border-box; /* Đảm bảo kích thước sau khi thêm viền */
  transition: transform 0.5s, box-shadow 0.5s, background-color 0.5s; /* Add transition for smooth effect */
  z-index: 1; /* Set a standard z-index for the normal state */
  background-color: gray;
  /* filter: blur(0.5px); */
}
.projects_submain:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
  cursor: pointer; /* Change the cursor to signify an interactive element */
  z-index: 3;
  background-color: yellow;
  /* filter: blur(0px); */
}
.projects_submain_game_02 {
  width: 60px;
  height: 60px;
  /* border: 1px solid black; */
  box-sizing: border-box; /* Đảm bảo kích thước sau khi thêm viền */
  transition: transform 0.5s, box-shadow 0.5s, background-color 0.5s; /* Add transition for smooth effect */
  z-index: 1; /* Set a standard z-index for the normal state */
}
.projects_submain_game_02:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow for depth */
  cursor: pointer; /* Change the cursor to signify an interactive element */
  z-index: 3;
  background-color: yellow;
  border-radius: 5px;
}
